.footer {
    background-color: rgb(30, 30, 32);
    position: relative;
    bottom: 0px;
    color: white;
    padding: 20px;
    text-align: center;
    font-family: 'Britannic Bold';
    src: url('./src/britanic-bold.ttf') format('truetype'); 
}
.footer .line {
    width: 100%;
    background-color: white;
    height: 1px;
}

.footer p {
    font-size: 20px;
}